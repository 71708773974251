<template>
  <section>
    <router-view v-if="menuFunctions.VIEW_DASHBOARD"></router-view>
    <dont-have-permission-page v-else/>
  </section>
</template>

<script>

export default {
  name: 'DashboardPage',
};
</script>
